<template>
  <div class="content-wrapper overflow-visible">
    <div class="page-header page-header-sticky page-header-sticky-open">
      <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

        <div class="page-header-info flex-1 py-3 text-uppercase">
          <div class="d-flex justify-content-between">
            <div>
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                  <span>{{row.ap_fullname||"-"}}</span>
                  <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                    title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                  <span class="ml-2 pl-2 border-left">{{rowReg.ar_reg_code||"-"}}</span>
                  <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                </h5>
              </div>
              <div class="phi-sub-info d-flex">
                <div class="phi-info d-flex flex-column">
                  <small>Tanggal Lahir</small>
                  <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Usia</small>
                  <strong
                    class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Jenis Kelamin</small>
                  <strong class="font-weight-semibold">{{row.cg_label || "-"}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Opsi Pembayaran</small>
                  <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Agama</small>
                  <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                </div>
                <div class="phi-info d-flex flex-column pl-2 ml-3">
                  <small>Dokter</small>
                  <strong class="font-weight-semibold">{{rowReg.bu_full_name || "-"}}</strong>
                </div>
              </div>
            </div>

            <div class="phi-info d-flex flex-column col-md-3 ml-auto">
              <a href="javascript:;" @click="toDetail(row)" data-toggle="modal" class="btn btn-outline-light mb-1">Lihat
                Kajian Perawat</a>
              <a href="javascript:;" @click="doOpenRiwayatRM(row)" data-toggle="modal"
                class="btn btn-outline-light">Lihat Riwayat Rekam Medis</a>

            </div>
          </div>
        </div>
      </div>
      <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
        <i class="icon-arrow-up5 align-middle"></i>
        <span class="align-middle">Sembunyikan Informasi Pasien</span>
      </a>
    </div>

    <template v-if="row.isFisio !== 'Y'">
      <div class="content overflow-hidden mt-3">
        <b-alert variant="info" show>
          Verifikasi pilihan ICD10 dan ICD9 pada bagian <strong>Assessment</strong>
        </b-alert>
        <validation-observer ref="VFormSubmit">
          <b-form @submit.prevent="doSubmit">
            <div class="no-slide-accordion">
              <div class="card">
                <div
                  class="card-header bg-info c-pointer collapse-head"
                  @click="toggleRmCollapse($event, 'subjective')"
                  :class="nonFisioCollapse.subjective ? null : 'collapsed'"  
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="card-title font-weight-semibold">Subjective</h5>
                    <i class="icon-arrow-down22"></i>
                  </div>
                </div>
                <b-collapse v-model="nonFisioCollapse.subjective">
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Keluhan Utama</h4>
                                    <p>{{row.apsd_keluhan||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Riwayat Penyakit</h4>
                                    <p>{{row.apsd_anamnesa||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Catatan Dokter</h4>
                                    <p>{{row.apsd_catatan_dokter||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
    
    
                    <div class="form-row mt-2">
                      <div class="col-md-6">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Alergi</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="form-row" v-if="row.apsd_has_alergi == 'Y'">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <th><strong>Jenis</strong></th>
                                        <th><strong>Nama</strong></th>
                                      </tr>
                                      <tr v-for="(v,k) in (row.apsd_alergi||[])" :key="k+'ob'">
                                        <td>
                                          <div class="result_tab">
                                            {{v.jenis||"-"}}
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            {{v.name||"-"}}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="form-row" v-else>
                              <div class="col-md-12">
                                <span>Tidak Ada Alergi</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="form-row" v-if="row.apsd_has_obat_rutin == 'Y'">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Keterangan</h4>
                                            <p>{{row.apsd_keterangan_obat_rutin||"-"}}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="form-row" v-else>
                              <div class="col-md-12">
                                <span>Tidak Ada Alergi</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="card">
                <div
                  class="card-header bg-info c-pointer collapse-head"
                  @click="toggleRmCollapse($event, 'objective')"
                  :class="nonFisioCollapse.objective ? null : 'collapsed'"  
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="card-title font-weight-semibold">Objective</h5>
                    <i class="icon-arrow-down22"></i>
                  </div>
                </div>
                <b-collapse v-model="nonFisioCollapse.objective">
                  <div class="card-body">
                    <div class="row">
    
                      <div class="col-md-12">
                        <div>
                          <div class="form-row mt-2">
                            <div class="col-md-12">
                              <div class="result_tab mb-2">
                                <h4>Objective</h4>
                                <p>{{row.apod_desc||"-"}}</p>
                              </div>
    
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="form-row">
                                    <div class="col-md-12">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Tekanan Darah</h4>
                                                  <p>{{row.apod_ttv_tekanan_darah_min}} / {{row.apod_ttv_tekanan_darah_max}}
                                                    mmHG</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Nadi</h4>
                                                  <p>{{row.apod_ttv_nadi||"-"}}/mnt</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Gula Darah</h4>
                                                  <p>{{row.apod_ttv_gula_darah||"-"}}mg/dL</p>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Pernafasan</h4>
                                                  <p>{{row.apod_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>SPO2</h4>
                                                  <p>{{row.apod_ttv_spo2||"-"}}%</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Suhu</h4>
                                                  <p>{{row.apod_ttv_suhu||"-"}}C</p>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Berat Badan</h4>
                                                  <p>{{row.apod_ttv_weight||"-"}}kg</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Tinggi</h4>
                                                  <p>{{row.apod_ttv_height||"-"}}Cm</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Lingkar Kepala</h4>
                                                  <p>{{row.apod_ttv_lingkar_kepala||"-"}}cm</p>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td v-if="row.ap_usia < 2">
                                                <div class="result_tab">
                                                  <h4>BMI</h4>
                                                  <p>{{row.apod_ttv_bmi}}m2</p>
                                                </div>
                                              </td>
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Label</h4>
                                                  <p>{{row.apod_ttv_label == 'I' ? 'IRREGULER':'REGULER'}}</p>
                                                </div>
                                              </td>
                                              <td v-if="row.ap_usia <= 15">
                                                <div class="result_tab">
                                                  <h4>Luas Permukaan Tubuh Anak</h4>
                                                  <p>{{row.apod_ttv_luas_permukaan_anak}}kg/m2</p>
                                                </div>
                                              </td>
                                              <!--
                                              <td>
                                                <div class="result_tab">
                                                  <h4>Kesadaran</h4>
                                                  <p>{{getConfigDynamic(mKesadaran,row.apod_ttv_kesadaran)||"-"}}</p>
                                                </div>
                                              </td>
                                              -->
                                              <td>
                                                  <div class="result_tab">
                                                  <h4>IMT</h4>
                                                  <p>{{row.auod_ttv_imt||"-"}}</p>
                                                  </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
    
                              <div class="card">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                                </div>
                                <div class="card-body p-3">
                                  <table class="table table-bordered">
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Kepala</h4>
                                          <p v-if="row.apod_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_kepala == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_kepala_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Mata</h4>
                                          <p v-if="row.apod_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_mata == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_mata_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Hidung</h4>
                                          <p v-if="row.apod_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_hidung == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_hidung_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Rambut</h4>
                                          <p v-if="row.apod_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_rambut == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_rambut_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Bibir</h4>
                                          <p v-if="row.apod_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_bibir == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_bibir_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Gigi Geligi</h4>
                                          <p v-if="row.apod_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_gigi == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_gigi_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Lidah</h4>
                                          <p v-if="row.apod_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_lidah == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_lidah_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Langit-Langit</h4>
                                          <p v-if="row.apod_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_langit == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_langit_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Leher</h4>
                                          <p v-if="row.apod_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_leher == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_leher_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Tenggorokan</h4>
                                          <p v-if="row.apod_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_tenggorokan_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Tonsil</h4>
                                          <p v-if="row.apod_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_tonsil == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_tonsil_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Dada</h4>
                                          <p v-if="row.apod_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_dada == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_dada_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Payudara</h4>
                                          <p v-if="row.apod_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_payudara == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_payudara_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Punggung</h4>
                                          <p v-if="row.apod_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_punggung == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_punggung_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Perut</h4>
                                          <p v-if="row.apod_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_perut == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_perut_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Genital</h4>
                                          <p v-if="row.apod_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_genital == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_genital_text||"-"}}</p>
                                        </div>
                                      </td>
    
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Anus</h4>
                                          <p v-if="row.apod_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_anus == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_anus_text||"-"}}</p>
                                        </div>
                                      </td>
    
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Lengan Atas</h4>
                                          <p v-if="row.apod_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_lengan_atas_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Lengan Bawah</h4>
                                          <p v-if="row.apod_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_lengan_bawah_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Jari Tangan</h4>
                                          <p v-if="row.apod_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_jari_tangan_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Kuku Tangan</h4>
                                          <p v-if="row.apod_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_kuku == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_kuku_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Sendi Tangan</h4>
                                          <p v-if="row.apod_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_sendi_tangan_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Tungkai Atas</h4>
                                          <p v-if="row.apod_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_tungkai_atas_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Tungkai Bawah</h4>
                                          <p v-if="row.apod_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_tungkai_bawah_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Jari Kaki</h4>
                                          <p v-if="row.apod_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_jari_kaki_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Kuku Kaki</h4>
                                          <p v-if="row.apod_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_kuku_kaki_text||"-"}}</p>
                                        </div>
                                      </td>
                                      <td width="33%">
                                        <div class="result_tab">
                                          <h4>Sendi Kaki</h4>
                                          <p v-if="row.apod_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                          <p v-else-if="row.apod_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                                          <p v-else>{{row.apod_vs_persendian_kaki_text||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                              
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="row gx-2">
                                    <div class="col-lg-4">
                                      <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                        <li class="nav-item" @click="changeTabAnat(k)"
                                          v-for="(v,k) in row.apod_lokasi_nyeri||[]" :key="k+'nav'">
                                          <a href="javascript:;" data-toggle="tab"
                                            :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                            <div class="d-flex justify-content-between align-items-center">
                                              <span>{{v.name||"(Anatomi Baru)"}}</span>
                                              <div>
                                                <i class="icon-chevron-right"></i>
                                              </div>
                                            </div>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="col-lg-8">
                                      <div class="tab-content">
                                        <div v-for="(v,k) in row.apod_lokasi_nyeri||[]" :key="k+'navAnat'"
                                          :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                          <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                            <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                                              <div class="body_wrapper">
                                                <img :src="assetLocal(v.image)" class="img-fluid w-100" alt="">
                                              </div>
                                              <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                                                :style="`top:${v1.y}%;left:${v1.x}%`">
                                                <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                                  :title="v1.value"></a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
    
                          </div>
    
                        </div>
                      </div>
    
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="card">
                <div
                  class="card-header bg-info c-pointer collapse-head"
                  @click="toggleRmCollapse($event, 'assessment')"
                  :class="nonFisioCollapse.subjective ? null : 'collapsed'"  
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="card-title font-weight-semibold">Assessment</h5>
                    <i class="icon-arrow-down22"></i>
                  </div>
                </div>
                <b-collapse v-model="nonFisioCollapse.assessment">
                  <div class="card-body">
                    <div class="alert alert-info">Verifikasi pilihan ICD-10 dan ICD-9-CM</div>
                    <div class="card">
                      <div class="card-header bg_head_panel shadow-0 border">
                        <span class="card-title font-weight-semibold mb-0">Verifikasi ICD</span>
                      </div>
    
                      <div class="card-group">
                        <div class="card border shadow-0">
                          <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Diagnosis Dokter</span>
                          </div>
                          <div class="card-body py-2" v-if="(dataICD10Dok||[]).length">
                            <div class="req-list">
                              <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                              <div v-for="(v,k) in (dataICD10Dok||[])" :key="k">
                                <template v-if="v.mi10_name">
                                  <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                  <span class="align-middle" data-popup="tooltip"
                                    title="Klik untuk menjadikan Diagnosis Primer">{{v.mi10_name}} <span
                                      class="font-weight-semibold">[{{v.mi10_code}}]</span></span>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                          </div>
    
                          <div class="card-body">
                            <div class="req-list">
                              <h6 class="text-uppercase mb-0">Diagnosa Lainnya</h6>
                              <div>
                                <span class="align-middle">{{row.apod_diagnosa||"-"}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card border shadow-0">
                          <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Verifikasi Diagnosis</span>
                          </div>
                          <div class="card-body">
                            <div class="form-group mb-0">
                              <div class="input-group">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                  <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                    v-model="inputICD10" @search="filterICD10($event,0)" @input="selectICD10(inputICD10)"
                                    :options="mICD10" label="text" :clearable="true" :reduce="v=>v.value">
    
                                    <template slot="selected-option">
                                      <span v-b-tooltip.hover.right :title="inputICD10Name">
                                        {{inputICD10Name||"-"}}
                                      </span>
                                    </template>
    
                                    <template slot="option" slot-scope="option">
                                      <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                    </template>
                                    <template #list-footer v-if="(mICD10||[]).length >= 1">
                                      <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                              class="icon-arrow-left22 text-white"></i></b-button>
                                          <small>Halaman {{mICD10[0].currentPage}} dari
                                            {{ceilData(mICD10[0].totalRows/5)}}</small>
                                          <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                              class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                      </li>
                                    </template>
                                  </v-select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                            <thead>
                              <tr>
                                <th width="64">Primer</th>
                                <th>Item ICD-10 dipilih</th>
                                <th width="64">Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                <td>
                                  <template>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input v-model="dataICD10Primer" :value="v.api10r_id" type="radio"
                                        name="primaryDiagnose10" class="custom-control-input"
                                        :id="`primaryDiagnose10${k}`" /><label class="custom-control-label"
                                        :for="`primaryDiagnose10${k}`"></label></div>
    
                                    <VValidate :name="'Diagnosa Primer'" v-model="dataICD10Primer" :rules="{required:1}" />
                                  </template>
                                </td>
                                <td><span>{{v.mi10_name||"-"}}<span
                                      class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                <td>
                                  <a href="javascript:;" @click="removedataICD10(v.api10r_id, k)"
                                    class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                    <i class="icon-trash-alt"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                          </div>
                        </div>
                      </div>
    
                      <div class="card-group mt-3">
                        <div class="card border shadow-0">
                          <div class="bg-light card-header">
                            <h6 class="card-title mb-0 font-weight-semibold">Tindakan Dokter</h6>
                          </div>
                          <div class="card-body py-2" v-if="(dataICD9Dok||[]).length">
                            <div class="req-list">
                              <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                              <div v-for="(v,k) in (dataICD9Dok||[])" :key="k">
                                <template v-if="v.mi9_name">
                                  <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                  <span class="align-middle" data-popup="tooltip"
                                    title="Klik untuk menjadikan Diagnosis Primer">{{v.mi9_name}} <span
                                      class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                          </div>
    
                          <div class="card-body">
                            <div class="req-list">
                              <h6 class="text-uppercase mb-0">Tindakan Lainnya</h6>
                              <div>
                                <span class="align-middle">{{row.apod_tindakan||"-"}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card border shadow-0">
                          <div class="bg-light card-header">
                            <h6 class="card-title mb-0 font-weight-semibold">Verifikasi Tindakan</h6>
                          </div>
                          <div class="card-body">
                            <div class="form-group mb-0">
                              <div class="input-group">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                  <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                    v-model="inputICD9" @search="filterICD9($event,0)" @input="selectICD9(inputICD9)"
                                    :options="mICD9" label="text" :clearable="true" :reduce="v=>v.value">
    
                                    <template slot="selected-option">
                                      <span v-b-tooltip.hover.right :title="inputICD9Name">
                                        {{inputICD9Name||"-"}}
                                      </span>
                                    </template>
    
                                    <template slot="option" slot-scope="option">
                                      <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                    </template>
                                    <template #list-footer v-if="(mICD9||[]).length >= 1">
                                      <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')" size="sm"
                                            variant="transparent" class="btn-icon bg-blue-300"><i
                                              class="icon-arrow-left22 text-white"></i></b-button>
                                          <small>Halaman {{mICD9[0].currentPage}} dari
                                            {{ceilData(mICD9[0].totalRows/5)}}</small>
                                          <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')" size="sm"
                                            variant="transparent" class="btn-icon bg-blue-300"><i
                                              class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                      </li>
                                    </template>
                                  </v-select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                            <thead>
                              <tr>
                                <th>Item ICD-9 dipilih</th>
                                <th width="64">Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                                <td><span>{{v.mi9_name||"-"}}<span
                                      class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                                <td>
                                  <a href="javascript:;" @click="removedataICD9(v.api9r_id, k)"
                                    class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                    <i class="icon-trash-alt"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                          </div>
                        </div>
                      </div>
    
                    </div>
                  </div>
                </b-collapse>
              </div>

              <div class="card">
                <div
                  class="card-header bg-info c-pointer collapse-head"
                  @click="toggleRmCollapse($event, 'planning')"
                  :class="nonFisioCollapse.subjective ? null : 'collapsed'"  
                >
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="card-title font-weight-semibold">Planning</h5>
                    <i class="icon-arrow-down22"></i>
                  </div>
                </div>
                <b-collapse v-model="nonFisioCollapse.planning">
                  <div class="card-body">
                    <div class="card" v-if="row.appdl_is_lab == 'Y'">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Laboratorium</h6>
                      </div>
                      <div class="card-body p-3">
                        <table class="table table-bordered mb-3">
                          <thead>
                            <tr class="table-light">
                              <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Prioritas Pemeriksaan</h4>
                                  <p v-if="row.appdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                  <p v-else>Non CITO</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Pasien Sedang Berpuasa?</h4>
                                  <p>{{row.appdl_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Catatan Permintaan</h4>
                                  <p>{{row.appdl_catatan||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Tanggal Pemeriksaan</h4>
                                  <p>{{row.appdl_penunjang_date | moment("DD MMM YYYY")}}</p>
                                </div>
                              </td>
    
                            </tr>
                          </tbody>
                        </table>
                        <div class="req-list-resume">
                          <div class="row g-3">
                            <template v-for="(v,k) in row.appdl_data">
                              <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                <div class="card shadow-0 border">
                                  <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                                  </div>
                                  <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.dubData||[])">
                                      <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)" :key="k1+'labdatas'">
                                        <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                        <template v-for="v2,k2 in (v1.data||[])">
                                          <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                            <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                            <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                            <span v-if="v2.notes" class="align-middle">, {{v2.notes}}</span>

                                          </div>
                                        </template>
                                      </div>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </template>
    
                            <div class="col-md-4 col-lg-3" v-if="row.appdl_hasil_lainnya">
                              <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                  <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                  <div class="req-list">
                                    <div>
                                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                      <span class="align-middle">{{row.appdl_hasil_lainnya||"-"}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
    
                            <span v-if="!selectedLabInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card" v-if="row.appdr_is_radio == 'Y'">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Radiologi</h6>
                      </div>
                      <div class="card-body p-3">
                        <table class="table table-bordered mb-3">
                          <thead>
                            <tr class="table-light">
                              <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Prioritas Pemeriksaan</h4>
                                  <p v-if="row.appdr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                  <p v-else>Non CITO</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                                  <p>{{row.appdr_alergi == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Catatan Permintaan</h4>
                                  <p>{{row.appdr_catatan||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Tanggal Pemeriksaan</h4>
                                  <p>{{row.appdr_penunjang_date | moment("DD MMM YYYY")}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="row g-3">
                          <template v-for="(v,k) in row.appdr_data">
                            <div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
                              <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                  <h6 class="card-title text-uppercase font-weight-semibold">{{
                                                getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                </div>
                                <div class="card-body py-2">
                                  <template v-for="v1,k1 in (v.data||[])">
                                    <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                      <span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
                                      <span v-if="v1.notes" class="align-middle">, {{v1.notes}}</span>

                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </template>
                          <div class="col-md-4 col-lg-3" v-if="row.appdr_hasil_lainnya">
                            <div class="card shadow-0 border">
                              <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                              </div>
                              <div class="card-body py-2">
                                <div class="req-list">
                                  <div>
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle">{{row.appdr_hasil_lainnya||"-"}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <span v-if="!selectedRadioInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Resep</h6>
                      </div>
                      <div class="card-body p-3">
                        <table class="table table-bordered table-sm">
                          <thead>
                            <tr>
                              <th>Jenis</th>
                              <th>Nama</th>
                              <th>Jumlah</th>
                              <th>Frekuensi</th>
                              <th>Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (resepDokter||[])" :key="'resepDokter'+k">
                              <td>
                                <div class="result_tab">
                                  <p>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <p v-if="v.ard_item_id == 99999">
                                    {{v.ard_item_lainnya||"-"}}
                                  </p>
                                  <p v-else>
                                    {{v.ard_nama||"-"}}
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <p>
                                    <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                    <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                                  </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <p>{{v.ard_keterangan || "-"}}</p>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="!(resepDokter||[]).length" class="table-info">
                              <td class="text-center" colspan="99">Tidak Ada Data</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                      </div>
                      <div class="card-body p-3">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="table-secondary">
                              <th colspan="2">Tindak Lanjut</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Tindak Lanjut</h4>
                                  <p>{{row.atld_is_edukasi == "Y"?'Edukasi':''}}</p>
                                  <p>{{row.atld_is_kontrol == "Y"?'Kontrol':''}}</p>
                                  <p>{{row.atld_is_rawat_inap == "Y"?'Rawat Inap':''}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <template v-if="row.atld_is_edukasi == 'Y'">
                                    <h4>Catatan Edukasi</h4>
                                    <p>{{row.atld_notes_edukasi||"-"}}</p>
                                  </template>
    
                                  <template v-if="row.atld_is_kontrol == 'Y'">
                                    <h4>Tanggal Pemeriksaan</h4>
                                    <p v-if="row.atld_notes_kontrol">{{row.atld_notes_kontrol|moment("DD MMM YYYY")}}</p>
                                    <span v-else> - </span>
                                  </template>
    
                                  <template v-if="row.atld_is_rawat_inap == 'Y'">
                                    <h4>Catatan Rawat Inap</h4>
                                    <p>{{row.atld_notes_rawat_inap||"-"}}</p>
                                  </template>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div class="card">
              <div class="card-footer">
                <div class="text-right">
                  <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                  <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
    <template v-else>
      
      <div class="content overflow-hidden mt-3">
        <div class="alert alert-info">Verifikasi pilihan ICD10 dan ICD9 pada bagian <strong>Assessment</strong></div>
        <validation-observer ref="VFormSubmit">
          <b-form @submit.prevent="doSubmit">
            <div class="alert alert-info">Klik Submit untuk verifikasi data</div>
            <div class="card">
              <div class="card-header bg_head_panel">
                <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
              </div>
              <div class="card-body p-3">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>No. Rekam Medis</h4>
                          <p>{{row.ap_code||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Nama Pasien</h4>
                          <p>{{row.ap_fullname||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Lahir</h4>
                          <p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Jenis Kelamin</h4>
                          <p>{{row.cg_label||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Anamnesa</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Anamnesa</h4>
                                    <p v-if="row.apdf_anamnesa !== 2">
                                      Pasien Sendiri
                                    </p>
                                    <p v-else>
                                      {{row.apdf_anamnesa_lainnya||"-"}}
                                    </p>

                                  </div>
                                </td>

                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Riwayat Penyakit Sekarang</h4>
                                    <p>{{row.apdf_riwayat_penyakit_sekarang||"-"}}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Diagnosis Klinis</h4>
                                    <p>{{row.apdf_riwayat_alergi||"-"}}</p>
                                  </div>
                                </td>

                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Riwayat Penyakit Dahulu</h4>
                                    <p>{{row.apdf_riwayat_penyakit_dahulu||"-"}}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Riwayat Pengobatan</h4>
                                    <p>{{row.apdf_riwayat_pengobatan||"-"}}
                                    </p>
                                  </div>
                                </td>

                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Riwayat Penyakit Keluarga</h4>
                                    <p>{{row.apdf_riwayat_penyakit_keluarga||"-"}}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Riwayat Pekerjaan, Sosial Ekonomi, Kejiawaan dan Kebiasaan</h4>
                                    <p>{{row.apdf_riwayat_pekerjaan||"-"}}
                                    </p>
                                  </div>
                                </td>

                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Status Gizi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Status Gizi</h4>
                                    <p v-if="row.apdf_status_gizi !== 'L'">
                                      {{row.apdf_status_gizi||"-"}}
                                    </p>
                                    <p v-else>
                                      {{row.apdf_status_gizi_lainnya||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pengkajian Nyeri
                    </h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="wrap_anatomy_info">
                          <label for="perKeluhan">Lokasi Nyeri<strong class="text-danger">*</strong></label>
                          <div class="position-relative" id="anatomiNyeri" @click.stop.prevent>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="body_wrapper">
                                  <img :src="assetLocal('img/front-body.png')" class="img-fluid" alt="">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="body_wrapper">
                                  <img :src="assetLocal('img/back-body.png')" class="img-fluid" alt="">
                                </div>
                              </div>
                            </div>
                            <div class="anatomy-info" v-for="(v,k) in row.apdf_lokasi_nyeri||[]" :key="k"
                              :style="`top:${v.y}%;left:${v.x}%`">
                              <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                :title="v.value"></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Diagnosis</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td colspan="2">
                                  <div class="result_tab">
                                    <h4>Keluhan</h4>
                                    <p>
                                      {{row.apdf_keluhan||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>


                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Rasa Nyeri</h4>
                                    <p>{{
                                        getConfigDynamic(Config.mr.statusNyeri,row.apdf_rasa_nyeri)||"-"}}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Kompres</h4>
                                    <p>{{row.apdf_kompres||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Aktifitas</h4>
                                    <p>{{row.apdf_aktifitas||"-"}}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Lama Nyeri yang di rasakan</h4>
                                    <p>{{row.apdf_lama_nyeri||"-"}}
                                      {{getConfigDynamic(Config.mr.configLamaNyeri,row.apdf_lama_nyeri)||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Apakah nyerinya berpindah dari satu ke tempat lain</h4>
                                    <p>
                                      {{getConfigDynamic(Config.mr.configNilaiNyeri,row.apdf_nilai_nyeri)||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">ICD 10
                    </h6>
                  </div>
                  <div class="card-body">
                    <div class="alert alert-info">Verifikasi pilihan ICD-10</div>
                    <div class="card">
                      <div class="card-header bg_head_panel shadow-0 border">
                        <span class="card-title font-weight-semibold mb-0">Verifikasi ICD</span>
                      </div>

                      <div class="card-group">
                        <div class="card border shadow-0">
                          <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Diagnosis Dokter</span>
                          </div>
                          <div class="card-body py-2" v-if="(dataICD10Dok||[]).length">
                            <div class="req-list">
                              <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                              <div v-for="(v,k) in (dataICD10Dok||[])" :key="k">
                                <template v-if="v.mi10_name">
                                  <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                  <span class="align-middle" data-popup="tooltip"
                                    title="Klik untuk menjadikan Diagnosis Primer">{{v.mi10_name}} <span
                                      class="font-weight-semibold">[{{v.mi10_code}}]</span></span>
                                </template>
                              </div>
                            </div>
                          </div>
                          <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                          </div>

                          <div class="card-body">
                            <div class="req-list">
                              <h6 class="text-uppercase mb-0">Diagnosa Lainnya</h6>
                              <div>
                                <span class="align-middle">{{row.apdf_diagnosa||"-"}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card border shadow-0">
                          <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Verifikasi Diagnosis</span>
                          </div>
                          <div class="card-body">
                            <div class="form-group mb-0">
                              <div class="input-group">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                  <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                    v-model="inputICD10" @search="filterICD10($event,0)"
                                    @input="selectICD10(inputICD10)" :options="mICD10" label="text" :clearable="true"
                                    :reduce="v=>v.value">

                                    <template slot="selected-option">
                                      <span v-b-tooltip.hover.right :title="inputICD10Name">
                                        {{inputICD10Name||"-"}}
                                      </span>
                                    </template>

                                    <template slot="option" slot-scope="option">
                                      <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                    </template>
                                    <template #list-footer v-if="(mICD10||[]).length >= 1">
                                      <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                          <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                              class="icon-arrow-left22 text-white"></i></b-button>
                                          <small>Halaman {{mICD10[0].currentPage}} dari
                                            {{ceilData(mICD10[0].totalRows/5)}}</small>
                                          <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                              class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                      </li>
                                    </template>
                                  </v-select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                            <thead>
                              <tr>
                                <th width="64">Primer</th>
                                <th>Item ICD-10 dipilih</th>
                                <th width="64">Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                <td>
                                  <template>
                                    <div class="custom-control custom-radio custom-control-inline">
                                      <input v-model="dataICD10Primer" :value="v.api10r_id" type="radio"
                                        name="primaryDiagnose10" class="custom-control-input"
                                        :id="`primaryDiagnose10${k}`" /><label class="custom-control-label"
                                        :for="`primaryDiagnose10${k}`"></label></div>

                                    <VValidate :name="'Diagnosa Primer'" v-model="dataICD10Primer"
                                      :rules="{required:1}" />
                                  </template>
                                </td>
                                <td><span>{{v.mi10_name||"-"}}<span
                                      class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                <td>
                                  <a href="javascript:;" @click="removedataICD10(v.api10r_id, k)"
                                    class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                    <i class="icon-trash-alt"></i>
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">ICD 9
                    </h6>
                  </div>

                  <div class="card-group mt-3">
                    <div class="card border shadow-0">
                      <div class="bg-light card-header">
                        <h6 class="card-title mb-0 font-weight-semibold">Tindakan Dokter</h6>
                      </div>

                      <div class="card-body py-2" v-if="(dataICD9Dok||[]).length">
                        <div class="req-list">
                          <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                          <div v-for="(v,k) in (dataICD9Dok||[])" :key="k">
                            <template v-if="v.mi9_name">
                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                              <span class="align-middle" data-popup="tooltip"
                                title="Klik untuk menjadikan Diagnosis Primer">{{v.mi9_name}} <span
                                  class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                            </template>
                          </div>
                        </div>
                      </div>

                      <div class="card-body py-2" v-else>
                        <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                      </div>

                      <div class="card-body">
                        <div class="req-list">
                          <h6 class="text-uppercase mb-0">Tindakan Manual</h6>
                          <div>
                            <span class="align-middle">{{row.apdf_tindakan||"-"}}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                          <h6 class="card-title mb-0 font-weight-semibold">Verifikasi Tindakan</h6>
                        </div>
                        <div class="card-body">
                          <div class="form-group mb-0">
                            <div class="input-group">
                              <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                  v-model="inputICD9" @search="filterICD9($event,0)" @input="selectICD9(inputICD9)"
                                  :options="mICD9" label="text" :clearable="true" :reduce="v=>v.value">

                                  <template slot="selected-option">
                                    <span v-b-tooltip.hover.right :title="inputICD9Name">
                                      {{inputICD9Name||"-"}}
                                    </span>
                                  </template>

                                  <template slot="option" slot-scope="option">
                                    <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                  </template>
                                  <template #list-footer v-if="(mICD9||[]).length >= 1">
                                    <li class="list-paging">
                                      <div class="d-flex align-items-center justify-content-between">
                                        <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')"
                                          size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                            class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{mICD9[0].currentPage}} dari
                                          {{ceilData(mICD9[0].totalRows/5)}}</small>
                                        <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')"
                                          size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                            class="icon-arrow-right22 text-white"></i></b-button>
                                      </div>
                                    </li>
                                  </template>
                                </v-select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                          <thead>
                            <tr>
                              <th>Item ICD-9 dipilih</th>
                              <th width="64">Aksi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                              <td><span>{{v.mi9_name||"-"}}<span
                                    class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                              <td>
                                <a href="javascript:;" @click="removedataICD9(v.api9r_id, k)"
                                  class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                  <i class="icon-trash-alt"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="card-body py-2" v-else>
                          <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="col-md-12 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Daftar masalah kedokteran fisik dan rehabilitas
                    </h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Mobilisasi</h4>
                                    <p>{{row.apdf_mobilisasi||"-"}}
                                    </p>
                                  </div>
                                </td>

                                <td>
                                  <div class="result_tab">
                                    <h4>ADL</h4>
                                    <p>{{row.apdf_adl||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Komunikasi </h4>
                                    <p>{{row.apdf_komunikasi||"-"}}
                                    </p>
                                  </div>
                                </td>

                                <td>
                                  <div class="result_tab">
                                    <h4>Psikologis</h4>
                                    <p>{{row.apdf_psikologi||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Sosial Ekonomi </h4>
                                    <p>{{row.apdf_sosial_ekonomi||"-"}}
                                    </p>
                                  </div>
                                </td>

                                <td>
                                  <div class="result_tab">
                                    <h4>Vokasional</h4>
                                    <p>{{row.apdf_vokasional||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Lain-lain </h4>
                                    <p>{{row.apdf_lainnya||"-"}}
                                    </p>
                                  </div>
                                </td>


                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-4 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Kedokteran fisik dan rehabilitasi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <p>{{row.apdf_rencana_kedokteran||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              
              </div>
              
              <div class="col-md-4 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana pemeriksaan penunjang</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <p>{{row.apdf_rencana_pemerikasaan_penunjang||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-4 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Dirujuk</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">

                                    <p v-if="row.apdf_dirujuk !== 'L'">
                                      {{row.apdf_dirujuk||"-"}}
                                    </p>
                                    <p v-else>
                                      {{row.apdf_dirujuk_lainnya||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="wrap_line">
                          <h3>Elektroterapi</h3>
                          <div v-for="(v,k) in row.apdf_rencana_tindakan_elektroterapi||[]" :key="k+'elek'"
                            class="form-row">
                            <div class="col-md-12 mt-2">
                              <div class="d-flex align-items-center">
                                <i class="icon-checkmark-circle text-success"></i>
                                <span class="ml-1">{{v}}</span>
                              </div>
                            </div>
                          </div>
                          <span v-if="!(row.apdf_rencana_tindakan_elektroterapi||[]).length">Tidak Ada Tindakan</span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="wrap_line">
                          <h3>Mekanoterapi</h3>
                          <div v-for="(v,k) in row.apdf_rencana_tindakan_mekanoterapi||[]" :key="k+'meka'"
                            class="form-row">
                            <div class="col-md-12 mt-2">
                              <div class="d-flex align-items-center">
                                <i class="icon-checkmark-circle text-success"></i>
                                <span class="ml-1">{{v}}</span>
                              </div>
                            </div>
                          </div>
                          <span v-if="!(row.apdf_rencana_tindakan_mekanoterapi||[]).length">Tidak Ada Tindakan</span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="wrap_line">
                          <h3>Latihan - latihan</h3>
                          <div v-for="(v,k) in row.apdf_rencana_tindakan_latihan||[]" :key="k+'lati'"
                            class="form-row">
                            <div class="col-md-12 mt-2">
                              <div class="d-flex align-items-center">
                                <i class="icon-checkmark-circle text-success"></i>
                                <span class="ml-1">{{v}}</span>
                              </div>
                            </div>
                          </div>
                          <span v-if="!(row.apdf_rencana_tindakan_latihan||[]).length">Tidak Ada Tindakan</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Resep</h6>
                  </div>
                  <div class="card-body p-3">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>Jenis</th>
                          <th>Nama</th>
                          <th>Jumlah</th>
                          <th>Frekuensi</th>
                          <th>Keterangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (resepDokter||[])" :key="'resepDokter'+k">
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p v-if="v.ard_item_id == 99999">
                                {{v.ard_item_lainnya||"-"}}
                              </p>
                              <p v-else>
                                {{v.ard_nama||"-"}}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>
                                <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_keterangan || "-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!(resepDokter||[]).length" class="table-info">
                          <td class="text-center" colspan="99">Tidak Ada Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <div class="card-footer">
                  <div class="text-right">
                    <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>

    <b-modal v-model="openDetailKajian"
      :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
      <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
      <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
    </b-modal>

    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
      <RiwayatRM v-bind="passToSubRM" />
    </b-modal>

  </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  const moment = require('moment')
  import DetailKajianAwal from './DetailKajianAwal.vue'
  import DetailKajianLanjutan from './DetailKajianLanjutan.vue'
  import RiwayatRM from './RiwayatRM.vue'

  export default {
    extends: GlobalVue,
    data() {
      return {
        rowReg: {},
        mKesadaran: [],
        dataICD9: [],
        dataICD9Primer: null,
        dataICD10: [],
        dataICD10Primer: null,
        dataICD10Dok: [],
        dataICD9Dok: [],


        mICD9: [],
        mICD10: [],
        mRadiologi: [],
        mLab: [],
        riwayatObat: [],
        resepDokter: [],
        mObatPreferensi: [],
        mDosis: [],
        mAlatKesehatan: [],
        mRacikan: [],
        activeAnat: 0,


        // sini disini ya
        openDetailKajian: false,
        openTindakan: false,
        mPekerjaan: [],
        mAlatBantu: [],
        mSkrininGizi: [],
        mSkrininGiziV2: [],
        mSDKI: [],
        mSLKI: [],
        mSIKI: [],
        mPoli: [],
        mDokter: [],

        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],

        dataRiwayatRMFollowUp: {},
        diagnosa: [],
        pemeriksaanFisik: false,

        inputICD9: null,
        inputICD10: null,

        inputICD9Name: null,
        inputICD10Name: null,

        searchICD10Name: null,
        searchICD9Name: null,

        nonFisioCollapse: {
          subjective: true,
          objective: false,
          assessment: false,
          planning: false,
        },
      }
    },
    components: {
      DetailKajianAwal,
      DetailKajianLanjutan,
      RiwayatRM
    },
    computed: {
      passToSub() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          mPekerjaan: this.mPekerjaan,
          mAlatBantu: this.mAlatBantu,
          mSkrininGizi: this.mSkrininGizi,
          mSkrininGiziV2: this.mSkrininGiziV2,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSLKI: this.mSLKI,
          mSIKI: this.mSIKI,
          mPoli: this.mPoli,
          mDokter: this.mDokter,
          mKesadaran: this.mKesadaran,
          historyKajianPerawat: this.historyKajianPerawat
        })
      },
      passToSubLanjutan() {
        return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSIKI: this.mSIKI,
          mKesadaran: this.mKesadaran,
          historyKajianPerawat: this.historyKajianPerawat
        })
      },
      passToSubRM() {
        return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,

          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,

          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
        })
      },
    },
    mounted() {
      this.apiGet()
      this.apiGetMaster()
      setTimeout(() => {
        this.initSticky()
      }, 1000)
    },
    methods: {
      toggleRmCollapse(e, collapseParamKey){
        e.stopPropagation()
        this.nonFisioCollapse[collapseParamKey] = !this.nonFisioCollapse[collapseParamKey]
        if(this.nonFisioCollapse[collapseParamKey]){
          const mainTarget = e.target.classList.contains('card-header') ? e.target : e.target.parentElement
          const pageHeaderOpen = document.querySelector('.page-header-sticky.page-header-sticky-open')
          const addCount = pageHeaderOpen ? pageHeaderOpen.offsetHeight : 0
          let count, addHeight = document.querySelector('.navbar').offsetHeight + addCount + 20
          setTimeout(() => {
            count = Math.round(mainTarget.parentElement.getBoundingClientRect().top + document.documentElement.scrollTop) - addHeight
            $("html, body").scrollTop(count)
          }, 50)
        }
      },
      apiGetMaster() {
        let slugs = (this.pageSlug ? "/" + this.pageSlug : "") + (this.pageId ? "/" + this.pageId : "")

        Gen.apiRest(
          "/get/" + this.modulePage +
          slugs + `?master=1&regId=${this.$route.query.regId}`, {}
        ).then(res => {
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
        })
      },
      // collapseShown(e){
      //   console.info("Hello!")
      // },
      ceilData(value) {
        return Math.ceil(value)
      },
      initSticky() {
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if (PageHeaderSticky) {
          const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
          if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
            PageHeaderToggle.addEventListener('click', function (e) {
              // e.preventDefault()
              PageHeaderSticky.classList.toggle('page-header-sticky-open')
              if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
              } else {
                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
              }
            })
          }
        }
      },

      filterICD10: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD10 || []).length; i++) {
          if (this.dataICD10[i]['api10r_icd_10']) {
            id.push(this.dataICD10[i]['api10r_icd_10'])
          }
        }

        this.searchICD10Name = e

        Gen.apiRest('/do/' + 'RekamMedis', {
          data: {
            type: 'select-icd-10',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mICD10 = res.data.data
        })
      }, 10),


      changePageICD10(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }

        let data = {
          type: 'select-paging-icd-10',
          page: page,
          search:  this.searchICD10Name
        }

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mICD10 = res.data.data
          this.getmICD10(this.dataICD10, k)
        })
      },

      filterICD9: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD9 || []).length; i++) {
          if (this.dataICD9[i]['api9r_icd_9']) {
            id.push(this.dataICD9[i]['api9r_icd_9'])
          }
        }
       
        this.searchICD9Name = e
       
        Gen.apiRest('/do/' + 'RekamMedis', {
          data: {
            type: 'select-icd-9',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mICD9 = res.data.data
        })
      }, 10),

      changePageICD9(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }
        let data = {
          type: 'select-paging-icd-9',
          page: page,
          search:  this.searchICD9Name
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mICD9 = res.data.data
          this.getmICD9(this.dataICD9, k)
        })
      },


      getmICD9(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.api9r_icd_9
        })
        return _.filter(this.mICD9, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      getmICD10(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.api10r_icd_10
        })
        return _.filter(this.mICD10, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },
      changeTabAnat(k) {
        this.activeAnat = k
      },

      selectICD9(e) {
        let index = this.mICD9.findIndex(x => x.value == e)
        if (index !== -1) {
          this.inputICD9Name = this.mICD9[index]['text']
          this.searchICD9Name = null
        } else {
          this.inputICD9Name = null
        }
        this.addNewICD9()
      },
      selectICD10(e) {
        let index = this.mICD10.findIndex(x => x.value == e)
        if (index !== -1) {
          this.inputICD10Name = this.mICD10[index]['text']
          this.searchICD10Name = null        
        } else {
          this.inputICD10Name = null
        }
        this.addNewICD10()
      },

      doSubmit() {
        this.$refs['VFormSubmit'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }
                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }
          
          if(!(this.dataICD10||[]).length){
            return  this.$swal({
              icon: 'warning',
              title: 'Tidak ada ada ICD 10',
            })
          }

          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.rowReg
                data.dataICD9 = this.dataICD9

                // if((data.dataICD9||[]).length){
                //     let indexPrimary = this.dataICD9.findIndex(x => x.api9r_id == this.dataICD9Primer)
                //     data.dataICD9[indexPrimary]['api9r_is_primary'] = 'Y'
                // }

                for (let i = 0; i < (this.dataICD10 || []).length; i++) {
                  this.dataICD10[i]['api10r_is_primary'] = 'N'
                }
                data.dataICD10 = this.dataICD10
                if ((data.dataICD10 || []).length) {
                  let indexPrimary2 = this.dataICD10.findIndex(x => x.api10r_id == this.dataICD10Primer)
                  data.dataICD10[indexPrimary2]['api10r_is_primary'] = 'Y'
                }

                data.type = 'submit-rm'
                Gen.apiRest(
                  "/do/" + 'VerifikasiRM', {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok'
                  }).then(result => {
                    if (result.value) {
                      this.$router.push({
                        name: 'Dashboard'
                      }).catch(() => {})
                    }
                  })
                }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })
              }
            })
          }
        })
      },
      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },

      isShowHeadLab(i) {
        let isData = 0
        for (let j = 0; j < (this.row.appdl_data[i]['dubData'] || []).length; j++) {
          for (let k = 0; k < (this.row.appdl_data[i]['dubData'][j]['data'] || []).length; k++) {
            if (this.row.appdl_data[i]['dubData'][j]['data'][k]['selected']) {
              isData += 1
            }
          }
        }
        return isData
      },
      isShowHeadSubHeadLab(i, j) {
        let isData = 0
        for (let k = 0; k < (this.row.appdl_data[i]['dubData'][j]['data'] || []).length; k++) {
          if (this.row.appdl_data[i]['dubData'][j]['data'][k]['selected']) {
            isData += 1
          }
        }
        return isData
      },
      isShowLab(i, j, k) {
        let isData = 0
        if (this.row.appdl_data[i]['dubData'][j]['data'][k]['selected']) {
          isData += 1
        }
        return isData
      },

      isShowHeadRadiologi(j) {
        let isData = 0
        for (let k = 0; k < (this.row.appdr_data[j]['data'] || []).length; k++) {
          if (this.row.appdr_data[j]['data'][k]['selected']) {
            isData += 1
          }
        }
        return isData
      },
      isShowRadiologi(j, k) {
        let isData = 0
        if (this.row.appdr_data[j]['data'][k]['selected']) {
          isData += 1
        }
        return isData
      },

      selectedLabInput() {
        let data = []
        for (let ik = 0; ik < (this.row.appdl_data || []).length; ik++) {
          for (let jk = 0; jk < (this.row.appdl_data[ik]['dubData'] || []).length; jk++) {
            for (let kk = 0; kk < (this.row.appdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
              if (this.row.appdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                data.push(this.row.appdl_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
            }
          }
        }
        if (this.row.appdl_hasil_lainnya) {
          data.push(this.row.appdl_hasil_lainnya)
        }
        return data.join(", ")
      },

      selectedRadioInput() {
        let data = []
        for (let i = 0; i < (this.row.appdr_data || []).length; i++) {
          for (let j = 0; j < (this.row.appdr_data[i]['data'] || []).length; j++) {
            if (this.row.appdr_data[i]['data'][j]['selected']) {
              data.push(this.row.appdr_data[i]['data'][j]['text'])
            }
          }
        }
        if (this.row.appdr_hasil_lainnya) {
          data.push(this.row.appdr_hasil_lainnya)
        }
        return data.join(", ")
      },

      toDetail(v) {
        this.loadingOverlay = true
        Gen.apiRest(
          "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianPerawat + '?regId=' + v.arm_ar_id,
        ).then(res => {
          this.loadingOverlay = false
          _.forEach(res.data, (v, k) => {
            this.$set(this, k, v)
          })
          this.openDetailKajian = true
        })
      },
      doOpenRiwayatRM(v) {
        console.log(v)
        this.loadingOverlay = true
        if (v.kajianDokter) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokter = res.data
            this.dataRiwayatRMDokter.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokter = {}
        }

        if (v.kajianDokterTerapi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.arm_ar_id,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterTerapi = res.data
            this.dataRiwayatRMDokterTerapi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterTerapi = {}
        }

        if (v.kajianGizi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMDokterGizi = res.data
            this.dataRiwayatRMDokterGizi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMDokterGizi = {}
        }

        if (v.kajianFisio) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMFisio = res.data
            this.dataRiwayatRMFisio.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMFisio = {}
        }

        if (v.kajianRadiologi) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMRadiologi = res.data
            this.dataRiwayatRMRadiologi.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMRadiologi = {}
        }

        if (v.kajianLab) {
          Gen.apiRest(
            "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + this.$route.query.regId,
          ).then(res => {
            this.loadingOverlay = false
            this.dataRiwayatRMLab = res.data
            this.dataRiwayatRMLab.Config = this.Config
            this.openRwiayatRM = true
          })
        } else {
          this.dataRiwayatRMLab = {}
        }

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-obat-farmasi',
              regId: v.arm_ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.arm_ar_id
            }
          }
        ).then(res => {
          let resp = res.data
          // non ppa
          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          
          this.loadingOverlay = false
        })

        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: this.$route.query.regId
            }
          }
        ).then(res => {
          let resp = res.data
          this.loadingOverlay = false
        
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab
        })


        Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.arm_ar_id,
        ).then(res => {
          this.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
        })


        setTimeout(() => {
          this.loadingOverlay = false
          this.openRwiayatRM = true
        }, 2000)
      },

      addNewICD9() {
        if (this.inputICD9) {
          // if (!this.row.isEdit) {
            let data = {
              api9r_arm_id: this.row.arm_id,
              api9r_ar_id: this.rowReg.ar_id,
              api9r_icd_9: this.inputICD9,
              type: 'add-icd-9-rm'
            }
            Gen.apiRest(
              "/do/" + 'RekamMedis', {
                data: data
              },
              "POST"
            ).then(res => {
              let resp = res.data
              this.dataICD9.push(resp.row)
              this.inputICD9 = null
            })
          // } else {
          //   let data = {
          //     api9r_arm_id: this.row.arm_id,
          //     api9r_id: null
          //   }
          //   this.dataICD9.push(data)
          // }
        }
      },

      removedataICD9(id, k) {
        // if (!this.row.isEdit) {
          let data = {
            id: id,
            type: 'remove-icd-9-rm'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.dataICD9.splice(k, 1)
          })
        // } else {
        //   this.dataICD9.splice(k, 1)
        // }
      },

      addNewICD10() {
        if (this.inputICD10) {
          // if (!this.row.isEdit) {
            let data = {
              api10r_arm_id: this.row.arm_id,
              api10r_ar_id: this.rowReg.ar_id,
              api10r_icd_10: this.inputICD10,
              type: 'add-icd-10-rm'
            }
            Gen.apiRest(
              "/do/" + 'RekamMedis', {
                data: data
              },
              "POST"
            ).then(res => {
              let resp = res.data
              this.dataICD10.push(resp.row)
              this.inputICD10 = null
            })
          // } else {
          //   let data = {
          //     api10r_arm_id: this.row.arm_id,
          //     api10r_id: null
          //   }
          //   this.dataICD10.push(data)
          // }
        }
      },

      removedataICD10(id, k) {
        // if (!this.row.isEdit) {
          let data = {
            id: id,
            type: 'remove-icd-10-rm'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.dataICD10.splice(k, 1)
          })
        // } else {
        //   this.dataICD10.splice(k, 1)
        // }
      },

      autoSaveICD9: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-icd-9-rm',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),

      autoSaveICD10: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-icd-10-rm',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),
    },
    watch: {
      $route() {
        this.apiGet()
        this.apiGetMaster()
      },
      'openDetailKajian'(v) {
        if (!v) {
          this.apiGet()
        }
      },
      'openRwiayatRM'(v) {
        if (!v) {
          this.apiGet()
        }
      },
      dataICD9: {
        handler(v) {
          this.autoSaveICD9(v)
        },
        deep: true
      },
      dataICD10: {
        handler(v) {
          this.autoSaveICD10(v)
        },
        deep: true
      },
    }
  }
</script>